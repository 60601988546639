.countdown-container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    color: black;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .time-unit {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 15px;
  }
  
  .time-unit span {
    font-size: 3rem;
    font-weight: 700;
    letter-spacing: 1px;
  }
  
  .time-unit p {
    font-size: 1rem;
    font-weight: 400;
    color: gray;
    margin-top: 10px;
  }
  
  .countdown-container > .time-unit:first-child {
    margin-left: 0;
  }
  
  .countdown-container > .time-unit:last-child {
    margin-right: 0;
  }
  