.quote-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 20px auto;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .quote-container:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .quote-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .quote-text {
    font-size: 1.5em;
    font-style: italic;
    color: #333;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .quote-author {
    font-size: 1.2em;
    font-weight: bold;
    color: #555;
    text-align: center;
  }
  
  .quote-comment {
    font-size: 1em;
    color: #666;
    margin-top: 10px;
    text-align: center;
  }
  
  .quote-category {
    font-size: 0.9em;
    color: #888;
    margin-top: 10px;
    text-align: center;
  }
  
  .quote-loading,
  .quote-error {
    font-size: 1.2em;
    color: #999;
    text-align: center;
    margin-top: 20px;
  }
  