/* General Styling */
body {
    font-family: 'Helvetica Neue', sans-serif;
    background-color: #000000; /* Black background for a modern look */
    color: #ffffff; /* White text for high contrast */
    margin: 0;
    padding: 0;
  }
  
  h1 {
    font-size: 2.5rem;
    color: #ffffff; /* White heading */
    text-align: center;
    margin-bottom: 2rem;
    font-weight: 300;
  }
  
  /* Leaderboard Container */
  .leaderboard-container {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #111111; /* Slightly lighter black for the container */
    border-radius: 16px; /* Smooth rounded corners */
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.5); /* Apple-like subtle shadow */
  }
  
  /* Leaderboard List */
  .leaderboard-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .leaderboard-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    background-color: #1a1a1a; /* Dark grey for item background */
    border-radius: 12px;
    margin-bottom: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .leaderboard-item:hover {
    background-color: #292929; /* Slightly brighter on hover */
  }
  
  /* Rank and Name Styling */
  .leaderboard-rank {
    font-weight: 600;
    color: #f77f00; /* Orange accent for rank */
  }
  
  .leaderboard-name {
    font-weight: 400;
    color: #ffffff; /* Keep names clean in white */
    flex-grow: 1;
    margin-left: 1rem;
    font-size: 1.1rem;
  }
  
  /* Points, cNFTs and Totals Styling */
  .leaderboard-points, .leaderboard-cnfts, .leaderboard-total {
    font-size: 1.1rem;
    color: #ffffff; /* White text for uniformity */
  }
  
  .leaderboard-points {
    margin-right: 1rem;
  }
  
  .leaderboard-cnfts {
    margin-right: 1rem;
    color: #00b4d8; /* Blue accent for cNFTs */
  }
  
  .leaderboard-total {
    font-weight: bold;
    color: #80ed99; /* Green accent for the totals */
  }
  
  /* Animations */
  .leaderboard-item {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.6s ease forwards;
  }
  
  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .leaderboard-container {
      padding: 1.5rem;
    }
  
    .leaderboard-item {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .leaderboard-name {
      margin-left: 0;
      margin-bottom: 1rem;
    }
  }
  