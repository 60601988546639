
body {
    font-family: 'Arial', sans-serif;
    background-color: #666;
    color: #333;
    line-height: 1.6;
    padding: 20px;
}

ol {
    counter-reset: item;
    padding-left: 0;
    list-style: none;
    margin: 0;
}

ol li {
    counter-increment: item;
    background: #fff;
    padding: 20px;
    margin: 10px 0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    background-color: #999 !important;

}

ol li::before {
    content: counter(item);
    position: absolute;
    top: 50%;
    left: -40px;
    transform: translateY(-50%);
    background: #6c63ff;
    color: #fff;
    font-size: 1.2em;
    font-weight: bold;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
}

ol li p {
    margin: 0;
}

ol li strong {
    color: #6c63ff;
}

p {
    margin: 20px 0;
}
.isDisabled {
    background-color: #d3d3d3;
    color: #a9a9a9;
    border: 2px solid #a9a9a9;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: not-allowed;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .isNotDisabled {
    background-color: #4CAF50;
    color: white;
    border: 2px solid #4CAF50;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .isNotDisabled:hover {
    background-color: #45a049;
    border-color: #45a049;
  }
  
  .isNotDisabled:active {
    background-color: #3e8e41;
    border-color: #3e8e41;
  }
  .info{
    color: darkkhaki;
  }

  @media screen and (max-width: 600px) {

    .video{
        width: 100%;
    }
  }

  .container_video_image {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 20px;
    max-width: 1000px;
    margin: 0 auto;
    border: 1px solid #ddd;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .image_animal {
    max-width: 100%;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    object-fit: cover;
  }
  .back_title{
    color: #333;
  }
  .video {
    width: 100%;
    border: none;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 768px) {
    .container {
      grid-template-columns: 1fr;
    }
  
    .image_animal {
      width: 100%;
      margin-bottom: 20px;
    }
  
    .video {
      width: 100%;
      height: auto;
    }
  }
  /* Cyberpunk theme colors */
/* Cyberpunk theme colors */
/* Cyberpunk theme colors */
:root {
  --primary-color: #0ff;
  --secondary-color: #f0f;
  --background-color: #111;
  --text-color: #fff;
  --button-background: linear-gradient(45deg, #0ff, #f0f);
  --button-border: 2px solid #0ff;
  --button-hover: linear-gradient(45deg, #f0f, #0ff);
  --button-shadow: 0 0 10px #0ff, 0 0 20px #f0f;
}

/* Base styling */
body {
  color: var(--text-color);
  font-family: 'Orbitron', sans-serif;
  

}

/* Cyberpunk Button Styling */
.cyberpunk-button {
  background: var(--button-background);
  border: var(--button-border);
  color: var(--text-color);
  font-size: 1.5rem;
  padding: 0.5em 1.5em;
  cursor: pointer;
  transition: transform 0.3s ease, background 0.3s ease;
  box-shadow: var(--button-shadow);
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.cyberpunk-button::before, .cyberpunk-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--button-hover);
  z-index: -1;
  transition: transform 0.5s ease;
}

.cyberpunk-button::before {
  transform: scaleX(0);
  transform-origin: bottom right;
}

.cyberpunk-button::after {
  transform: scaleY(0);
  transform-origin: top left;
}

.cyberpunk-button:hover::before {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.cyberpunk-button:hover::after {
  transform: scaleY(1);
  transform-origin: top right;
}

.cyberpunk-button:hover {
  transform: scale(1.05);
  background: var(--button-hover);
  box-shadow: var(--button-shadow);
}

@keyframes buttonGlow {
  0% {
    box-shadow: 0 0 10px var(--primary-color), 0 0 20px var(--secondary-color);
  }
  50% {
    box-shadow: 0 0 20px var(--primary-color), 0 0 40px var(--secondary-color);
  }
  100% {
    box-shadow: 0 0 10px var(--primary-color), 0 0 20px var(--secondary-color);
  }
}

.cyberpunk-button {
  animation: buttonGlow 1.5s infinite alternate;
}
