/* Cyberpunk theme colors */
:root {
    --primary-color: #0ff;
    --secondary-color: #f0f;
    --background-color: #111;
    --text-color: #fff;
    --modal-background-color: rgba(0, 0, 0, 0.9);
    --border-glow: 0 0 10px var(--primary-color);
  }
  

  
  .anatomyModal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--modal-background-color);
    z-index: 1000;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.5s ease-in-out;
  }
  
  .AnatomyModalImageDiv {
    text-align: center;
  }
  
  .AnatomyModalImage {
    max-width: 90%;
    max-height: 80%;
    border: 2px solid var(--primary-color);
    box-shadow: var(--border-glow);
    animation: blinkBorder 1.5s infinite;
  }
  
  .anatomyTitle, .anatomyAuthor, .anatomyLicense {
    margin: 10px 0;
    text-align: center;
    color: var(--secondary-color);
    animation: textGlow 1.5s ease-in-out infinite alternate;
  }
  
  .anatomy {
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .anatomy img {
    border: 2px solid var(--primary-color);
    box-shadow: var(--border-glow);
  }
  
  .anatomy:hover {
    transform: scale(1.05);
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes blinkBorder {
    0%, 100% {
      box-shadow: 0 0 5px var(--primary-color);
    }
    50% {
      box-shadow: 0 0 20px var(--secondary-color);
    }
  }
  
  @keyframes textGlow {
    0% {
      text-shadow: 0 0 5px var(--secondary-color);
    }
    100% {
      text-shadow: 0 0 20px var(--primary-color);
    }
  }
  