/* sentimentAnalysis.css */

.sentiment-analysis {
    font-family: 'Arial', sans-serif;
    color: #333;
    padding: 20px;
    max-width: 700px;
    margin: 0 auto;
    background-color: #f0f4f8;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    


    
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
    color: #222;
    margin-bottom: 16px;
  }
  
  .sentiment-bar-container {
    width: 100%;
    height: 40px;
    background-color: #ddd;
    border-radius: 10px;
    margin-bottom: 8px;
    overflow: hidden;
  }
  
  .sentiment-bar {
    height: 100%;
    border-radius: 10px;
  }
  
  .sentiment-score {
    font-size: 18px;
    color: #444;
    margin-bottom: 16px;
  }
  
  .top-words {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .top-words-section {
    background-color: #eaf4fc;
    padding: 10px;
    border-radius: 6px;
  }
  
  .label {
    font-weight: bold;
    color: #222;
  }
  
  .top-words-section ul {
    list-style-type: none;
    padding: 0;
    font-size: 14px;
    color: #333;
  }
  
  .top-words-section ul li {
    background-color: #fff;
    margin: 5px 0;
    padding: 5px 10px;
    border-radius: 4px;
  }
  
  .loading-text {
    font-size: 16px;
    color: #888;
    text-align: center;
  }
 
  
  .summary-container {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
  }
  
  .summary-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .summary-content {
    font-size: 1rem;
    color: #333;
  }
  
  .summary-content > li{
margin-left: 30px;
  }

  .summary-content {
    padding: 30px !important;
    color: #000;
      }