.checkNFTs {
    display: inline-block;
    background: linear-gradient(135deg, #000000, #080808); /* Warm gradient with complementary colors */
    color: rgb(86, 158, 240); /* Contrast with background     background: rgb(86, 158, 240); /* Light overlay for effect */
    
    font-family: 'Poppins', sans-serif; /* Clean and modern font */
    font-size: 16px;
    padding: 12px 24px;
    border: none;
    border-radius: 3px; /* Smooth rounded corners */
    box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    cursor: pointer;
    transition: all 0.3s ease; /* Smooth transitions */
    position: relative;
    overflow: hidden;
  }
  
  .checkNFTs::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: rgba(86, 158, 240, 0.342); /* Light overlay for effect */
    transition: all 0.5s ease;
  }
  
  .checkNFTs:hover::before {
    left: 100%; /* Slide effect */
  }
  
  .checkNFTs:hover {
    background: linear-gradient(135deg, #111111, #000000); /* Reverse gradient on hover */
    box-shadow: 0px 6px 22px rgba(0, 0, 0, 0.4); /* Increase shadow on hover */
  }
  
  .checkNFTs:active {
    transform: scale(0.95); /* Slight scale down on click */
  }
  

  /* General styling for the card */
.mint-card {
  background-color: #1a1a1a;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  max-width: 300px;
  margin: 20px auto;
}

/* Image styling */
.imageForMint {
  max-width: 100%;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.imageForMint:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

/* Text content styling */
.card-content {
  text-align: center;
  color: #fff;
  margin-top: 10px;
}

.card-content h3 {
  font-size: 1.5rem;
  margin: 0;
  color: #00d1b2; /* Mint color */
}

.card-content p {
  font-size: 1rem;
  color: #ccc;
}

/* Responsive behavior */
@media screen and (max-width: 600px) {
  .mint-card {
    padding: 15px;
  }

  .card-content h3 {
    font-size: 1.2rem;
  }

  .card-content p {
    font-size: 0.9rem;
  }
}
