.contributions-container {
    background-color: #121212;
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .contribution-card {
    background-color: #1e1e1e;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  h2 {
    color: #ffa726;
    text-align: center;
  }
  
  h3 {
    color: #1976d2;
  }
  
  p {
    margin: 5px 0;
  }
  
  .approved {
    color: #4caf50;
  }
  
  .pending {
    color: #f44336;
  }
  