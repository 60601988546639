.pinterest-save-button {
    background-color: #e60023;
    color: white;
    font-family: 'Arial', sans-serif;
    font-size: 26px;
    border: none;
    border-radius: 50px;
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .pinterest-save-button:hover {
    background-color: #c5001a;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .pinterest-icon {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    margin-right: 8px;
  }
  
  @font-face {
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-size: 33px;
    font-weight: 900;
    src: url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/webfonts/fa-solid-900.woff2') format('woff2');
  }
  
  