:root {
    --primary-color3: #a1cfff;
    --secondary-color3: #ff85b3;
    --background-color3: #1e1e1e;
    --text-color3: #ffffff;
    --highlight-color3: #76ff03;
    --box-shadow-color3: rgba(0, 255, 234, 0.5);
  }
  

  
  .solana_tokens {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background-color: var(--background-color3);
    border: 2px solid var(--highlight-color3);
    border-radius: 12px;
    box-shadow: 0 0 15px var(--box-shadow-color3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .solana_tokens:hover {
    transform: scale(1.01);
    box-shadow: 0 0 25px var(--box-shadow-color3);
  }
  
  #token_image {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 50%;
    border: 2px solid var(--primary-color3);
    box-shadow: 0 0 10px var(--primary-color3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  #token_image:hover {
    transform: scale(1.01);
    box-shadow: 0 0 15px var(--primary-color3);
  }
  
  #my_tokens {
    font-size: 1.2rem;
    color: var(--text-color3);
  }
  
  .number_of_tokens {
    color: var(--secondary-color3);
    margin-left: 5px;
    font-size: 1.5rem;
    font-weight: bold;
    text-shadow: 0 0 5px var(--secondary-color3);
    transition: color 0.3s ease, text-shadow 0.3s ease;
  }
  
  .number_of_tokens:hover {
    color: var(--highlight-color3);
    text-shadow: 0 0 10px var(--highlight-color3);
  }
  