.render3D {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    box-shadow: 5 10px 20px rgba(8, 121, 252, 0.889);
    overflow: hidden;
    margin: 0px auto;
    width: 100% !important;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .render3D:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  }
  
  .render3D canvas {
    border-radius: 15px;
  }
  
  /* Media query for mobile devices */
@media (max-width:900px) {
  .render3D {
    width: 100%;
  }
}
.render3D {
border: 10px;
border-color: #001;
}