/* Container for the card and text element */
.card-container {
    position: relative;
    display: inline-block;
    margin: 10px;
  }
  
  /* Text element that triggers the popup */
  .text-element {
    cursor: pointer;
    font-size: 18px;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
  
  .text-element:hover {
    border-color: #000000;
    background-color: #afffb3;
    transform: scale(1.05);
  }
  .card-container{
    background-color: #f3f0f0 !important;
    padding: 4px;
    border-radius: 4px;
  }
  /* Popup card styling */
  .popup {

    opacity: 0;
    animation: fadeIn .7s forwards;
  }
  
  
  .close-button:hover {
    color: #ff0000;
  }
  
  /* Button styling */
