.cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    gap: 20px;
    padding: 20px;
  }
  .contribution_title{
    color: #fcf2e388;
  }
  .card {
    background-color: #121212;
    border: 1px solid #ffffff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease;
    color: #ffffff !important;
  }
  
  .card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  }
  
  .category-title {
    font-size: 1.5rem;
    color: #ffa726;
    margin-bottom: 10px;
  }
  
  .card-id {
    font-size: 0.9rem;
    color: #aaaaaa;
    margin-bottom: 10px;
  }
  
  .json-content {
    background-color: #1e1e1e;
    padding: 10px;
    border-radius: 5px;
    overflow-y: auto;
    color: #ffffff !important;
  }
  
  .error-message {
    color: #f44336;
  }
  