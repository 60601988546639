#privacy_policy{
    color: rgb(255, 255, 255);
    align-content: left;
    text-align: left;
}
.btnSignUp{
    height: 69px !important;
    top: 0px;
    right: 0px;
    transition: all .3s ease-in-out;
    font-size: 16px;
    color: aliceblue;
    font-weight: 600;
    width: 180px;
    background-color: #01CEFF;
    border: #4be9ee;
}
.btnSignUp:hover{
    cursor: pointer;
    height: 69px !important;
    top: 0px;
    right: 0px;
    transition: all .3s ease-in-out;
    font-size: 16px;
    color: aliceblue;
    font-weight: 600;
    width: 180px;
    background-color: #456e79;
    border: #4be9ee;
}