.quiz-container {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

.quiz-title {
  font-size: 28px;
  color: #333;
  text-align: center;
  margin-bottom: 10px;
}

span {
  display: block;
  text-align: center;
  font-size: 14px;
  color: #555;
  margin-bottom: 20px;
}

.question h2 {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
}

.option-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  background-color: #f5f5f5;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  transition: all 0.3s ease;
}

.option-button:hover {
  background-color: #e6f7ff;
  border-color: #007bff;
}

.option-button.selected {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.submit-button {
  width: 100%;
  padding: 12px;
  margin-top: 20px;
  background-color: #ff6600;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #ff4500;
}

.score {
  text-align: center;
  margin-top: 20px;
  font-size: 22px;
  color: #333;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .quiz-container {
    padding: 15px;
  }

  .quiz-title {
    font-size: 24px;
  }

  .option-button {
    font-size: 14px;
    padding: 8px;
  }

  .submit-button {
    font-size: 16px;
    padding: 10px;
  }
}
