
  .h1_category {
    text-align: center;
    color: #333;
  }
  
  .ul_category{
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .li_category {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
    max-width: 300px;
    text-align: center;
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .li_category:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .img_category {
    border-radius: 10px;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .h2_category {
    color: #333;
    margin: 15px 0 10px;
    font-size: 1.5em;
  }
  
  .p_category {
    color: #666;
    font-size: 1em;
    line-height: 1.5;
  }
  