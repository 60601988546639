.documentary-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
  }
  
  .documentary-container:hover {
    transform: translateY(-5px);
  }
  
  .title {
    font-size: 2em;
    margin-bottom: 20px;
    font-family: 'Merriweather', serif;
    color: #333;
  }
  
  .description {
    margin-bottom: 20px;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.6;
    color: #acacac;
  }
  
  .labels {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .label-box {
    background-color: #f0f0f0;
    color: #555;
    padding: 5px 10px;
    border-radius: 5px;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.9em;
    transition: background-color 0.3s ease;
  }
  
  .label-box:hover {
    background-color: #ddd;
  }
  
  .category {
    margin-bottom: 10px;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.9em;
    color: #777;
  }
  
  .video-container {
    margin-top: 20px;
  }
  
  .loading {
    text-align: center;
    font-size: 1.5em;
    color: #777;
  }
  
  @media (max-width: 600px) {
    .documentary-container {
      padding: 15px;
    }
  
    .title {
      font-size: 1.5em;
    }
  
    .description {
      font-size: 0.9em;
    }
  
    .video-container iframe {
      width: 100%;
      height: auto;
    }
  }
  
  .athleteDescription{
    color: #333 !important;
    text-align: left;
  }