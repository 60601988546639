.white-paper-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-family: 'Helvetica Neue', Arial, sans-serif;
}

.white-paper-content {
    line-height: 1.6;
    color: #333;
}

.white-paper-content h1,
.white-paper-content h2,
.white-paper-content h3 {
    margin: 20px 0 10px;
    color: #111;
}

.white-paper-content p {
    margin: 10px 0;
}

.white-paper-content a {
    color: #007bff;
    text-decoration: none;
    border-bottom: 1px solid #007bff;
    transition: color 0.2s, border-bottom-color 0.2s;
}

.white-paper-content a:hover {
    color: #0056b3;
    border-bottom-color: #0056b3;
}

.white-paper-content ul,
.white-paper-content ol {
    margin: 10px 0 10px 20px;
    padding-left: 20px;
}

.white-paper-content blockquote {
    margin: 20px 0;
    padding: 10px 20px;
    background-color: #f9f9f9;
    border-left: 5px solid #ccc;
    color: #555;
}
