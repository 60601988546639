.queen-coin-card {
    width: 300px; /* Fixed width */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Subtle shadow for depth */
    border-radius: 10px; /* Rounded corners for a softer look */
    overflow: hidden; /* Ensures nothing leaks outside the border radius */
    background-color: #fff; /* White background */
    margin: 20px; /* Spacing around the card */
    transition: transform 0.3s; /* Smooth transition for hover effect */
  }
  
  .queen-coin-card:hover {
    transform: translateY(-5px); /* Slight lift effect on hover */
  }
  
  .coin-image {
    width: 100%; /* Full-width image */
    height: 180px; /* Fixed height for images */
    object-fit: cover; /* Ensures the image covers the space without distorting */
  }
  
  .coin-info {
    padding: 15px; /* Padding inside the information area */
  }
  
  .coin-title {
    margin: 10px 0; /* Margin around the title for spacing */
    color: #333; /* Darker text for the title */
    font-size: 18px; /* Larger font size for emphasis */
  }
  
  .coin-description {
    color: #666; /* Lighter text for the description */
    font-size: 14px; /* Smaller font size for subtlety */
    line-height: 1.5; /* Spacing between lines */
  }
  