.animal-card {
    background: rgba(20, 20, 20, 0.9);
    border-radius: 15px;
    padding: 10px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    transition: transform 0.4s ease, box-shadow 0.4s ease;
  }
  
  .animal-card:hover {
    transform: scale(1.05);
    box-shadow: 0 12px 36px rgba(255, 0, 212, 0.4);
  }
  
  .card-section {
    background: rgba(30, 30, 30, 0.8);
    border-radius: 10px;
    margin: 15px 0;
    padding: 20px;
    width: 100%;
    max-width: 600px;
    text-align: center;
    box-shadow: 0 4px 12px rgba(57, 65, 65, 0.2);
    transition: background 0.3s ease, box-shadow 0.3s ease;
  }
  
  .card-section:hover {
    background: rgba(40, 40, 40, 0.9);
    box-shadow: 0 6px 18px rgba(233, 110, 171, 0.3);
  }
  
  #status {
    color: #a7ffff;
    font-weight: 600;
  }
  
  #lifespan,
  #captivity-lifespan,
  #weight,
  #body-length,
  #tall,
  #tail-length {
    color: #cccccc;
  }
  
  h2 {
    margin: 0;
    font-size: 1.3em;
    font-weight: 700;
    color: #a3a3a3;
    letter-spacing: 1px;
  }
  
  p {
    margin: 10px 0 0;
    font-size: 1.1em;
    color: #696969;
    line-height: 1.5em;
  }
  
  .animal-card h2,
  .animal-card p {
    font-family: "SF Pro Display", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  
  @keyframes glow {
    0% {
      box-shadow: 0 0 10px rgba(252, 136, 4, 0.2);
    }
    50% {
      box-shadow: 0 0 20px rgba(214, 126, 10, 0.4);
    }
    100% {
      box-shadow: 0 0 10px rgba(233, 162, 10, 0.2);
    }
  }
  
  .animal-card {
    animation: glow 2s infinite ease-in-out;
  }
  
  @media (max-width: 768px) {
    .animal-card {
      padding: 20px;
    }
  
    h2 {
      font-size: 1.1em;
    }
  
    p {
      font-size: 1em;
    }
  }
  