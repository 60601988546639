.containerCat {
    max-width: 1200px;
    margin: -20px auto;
    padding:  0px !important;
  }
  
  .category-list {
    list-style-type: none;
    padding: 0 !important;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: -20px;
    margin: 0 0 0 -25px !important;
  }
  
  .li_category {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .li_category:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .img_category {
    border-radius: 10px;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .h2_category {
    color: #333;
    margin: 15px 0 10px;
    font-size: 1.5em;
  }
  
  .p_category {
    color: #666;
    font-size: 1em;
    line-height: 1.5;
  }
  
  @media (min-width: 700px) {
    .category-list {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (max-width: 700px) {
    .category-list {
      grid-template-columns: 1fr;
    }
    .container{
      max-width: 100%;
    }
    .li_category{
      width: 100%;
    }
    body{
      max-width: 100%;
      overflow-x: hidden;
      
    }
    .img_category{
      width: 100%;
    }
   
  }
  


  #quiz-solved {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  color: rgb(75, 75, 75);
  background: linear-gradient(135deg, #00ffcc, #3df57a);
  padding: 20px 40px;
  text-align: center;
  border-radius: 12px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease;
}

#quiz-solved::before {
  content: '';
  position: absolute;
  top: -100%;
  left: -100%;
  width: 300%;
  height: 300%;
  background: radial-gradient(circle at center, rgba(54, 94, 66, 0.2), transparent);
  transition: all 0.5s ease;
}

#quiz-solved:hover {
  transform: translateY(-3px);
  background: linear-gradient(135deg, #2984df, #00ffcc);
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.5);
}

#quiz-solved:hover::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at center, rgba(255, 255, 255, 0.5), transparent);
}
