.species-container {
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 10px;
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.species-image-section {
  text-align: center;
  margin-bottom: 20px;
}

.species-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  border: 2px solid #ddd;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.species-image:hover {
  transform: scale(1.05);
}

.species-image-link {
  font-size: 12px;
  color: #666;
  margin-top: 5px;
}

.species-content {
  color: #333;
}

.species-distribution-html h3 {
  color: #555;
  margin-top: 20px;
}

.species-distribution-html ol {
  padding-left: 20px;
  margin: 10px 0;
}

.species-distribution-html li {
  margin-bottom: 10px;
}

.species-distribution {
  margin-top: 20px;
  font-size: 14px;
  color: #333;
}

.species-distribution-citation {
  font-size: 12px;
  color: #666;
  margin-top: 10px;
}

.species-citation {
  font-size: 12px;
  color: #888;
  margin-top: 20px;
  text-align: center;
}

/* Fullscreen Overlay Styles */
.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.fullscreen-image {
  max-width: 90%;
  max-height: 90%;
  border-radius: 10px;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  background: none;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-button:hover {
  color: #f00;
}
