.gallery-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
    padding: 20px;
    background-color: #121212;
    color: white;
  }
  
  .video-card {
    position: relative;
    width: 300px;
    height: 200px;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
  }
  
  .video-card:hover {
    transform: scale(1.05);
  }
  
  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (16 / 9) */
  }
  
  .styled-react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .description {
    padding: 10px;
    font-size: 14px;
    text-align: center;
    background: rgba(0, 0, 0, 0.7);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  @media (max-width: 768px) {
    .video-card{width: 100%}
  }  