@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

:root {
    --primary-color: hsl(17, 90%, 69%);
    --secondary-color2: #e9e8e8;
    --hover-color: #87e4a1;
    --font-color: #646363;
    --background-color: #f5f5f5;
    --box-shadow-color: #f5f5f5;
    --box-shadow-hover-color: rgba(15, 13, 13, 0.2);
}

.table-container {
    width: 100%;
    overflow-x: auto;
    margin: 0 auto;
    padding: 1rem;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    background-color: var(--background-color);
}

.classification-table {
    width: 100%;
    border-collapse: collapse;
    background-color: var(--background-color);
    color: var(--font-color);
    box-shadow: 0 4px 8px var(--box-shadow-color);
    border-radius: 8px;
    overflow: hidden;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    text-align: left;
}

.classification-table:hover {
    box-shadow: 0 8px 16px var(--box-shadow-hover-color);
    transform: translateY(5px);
}

.thead_specie {
    background-color: var(--primary-color);
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.thtaxon,
.thtaxon2 {
    padding: 12px;
    font-weight: 700;
    border-bottom: 2px solid var(--secondary-color2);
}

.tbody tr:nth-child(even) {
    background-color: var(--secondary-color2);
}

.tbody tr:hover {
    background-color: var(--hover-color);
    cursor: pointer;
    transform: scale(1.002);
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.trtaxon td {
    border: 1px solid #ddd;
    padding: 12px;
    font-weight: 400;
    transition: background-color 0.3s ease, transform 0.3s ease;
    text-align: left;
}

.trtaxon td:hover {
    background-color: var(--hover-color);
}

.trtaxon td:first-child {
    font-weight: 700;
}

@media screen and (max-width: 600px) {
    .thtaxon,
    .thtaxon2 {
        padding: 10px;
        font-size: 14px;
    }
    .video{
        width: 100%;
    }
    .trtaxon td {
        padding: 10px;
        font-size: 14px;
    }
}
