.concept-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .concept-title {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 10px;
  }
  
  .concept-author {
    font-size: 1.2em;
    color: #555;
    margin-bottom: 20px;
  }
  
  .concept-content {
    font-size: 1em;
    line-height: 1.6;
    color: #444;
    margin-bottom: 30px;
  }
  
  .concept-content p {
    margin-bottom: 15px;
  }
  
  .concept-content a {
    color: #1e90ff;
    text-decoration: none;
  }
  
  .concept-content a:hover {
    text-decoration: underline;
  }
  
  .concept-date {
    font-size: 0.9em;
    color: #888;
  }
  