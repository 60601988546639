.contribution-container {
  background-color: #121212;
  padding: 20px;
  border-radius: 10px;
  color: #fff;
  max-width: 100% !important;
  width: 100% !important;
  margin: 0 auto;
}

.add-button {
  background-color: #1976d2;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-button:hover {
  background-color: #1565c0;
}

.contribution-card {
  background-color: #1e1e1e;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}

.label {
  color: #fff;
}

input,
textarea,
select {
  width: 100%;
  padding: 0px;
  margin-top: 10px;
  border-radius: 5px;
  border: none;
  background-color: #333;
  color: #fff;
}

.text-area {
  min-height: 100px;
}

.author-field {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.submit-button {
  background-color: #ffa726;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #fb8c00;
}
