/* newsFeed.css */

.news-feed {
    font-family: 'Arial', sans-serif;
    color: #333;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f0f4f8;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .title {
    font-size: 28px;
    font-weight: bold;
    color: #222;
    margin-bottom: 20px;
  }
  
  .article-card {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
  }
  
  .article-card:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
  
  .article-title {
    font-size: 22px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .sentiment-slider-container {
    margin-bottom: 10px;
  }
  
  .sentiment-slider {
    width: 100%;
    height: 12px;
    -webkit-appearance: none;
    appearance: none;
    background: linear-gradient(90deg, red, yellow, green);
    border-radius: 6px;
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
  }
  
  .sentiment-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background-color: #333;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .sentiment-slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background-color: #333;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .sentiment-slider:hover {
    opacity: 1;
  }
  
  .summary, .why-grade, .word-lists, .labels-category {
    font-size: 16px;
    color: #555;
    margin-bottom: 10px;
  }
  
  .article-link {
    font-size: 16px;
    color: #1e90ff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .article-link:hover {
    text-decoration: underline;
  }
  
  .loading-text {
    font-size: 18px;
    color: #888;
    text-align: center;
  }
  