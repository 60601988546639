:root {
    --primary-color: #1e90ff;
    --secondary-color: #f1f1f1;
    --font-family: 'Montserrat', sans-serif;
    --transition-duration: 0.3s;
  }
  
  body {
    font-family: var(--font-family);
  }
  
  .intro-card {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: var(--secondary-color);
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform var(--transition-duration), box-shadow var(--transition-duration);
  }
  
  .intro-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .intro-content {
    font-size: 1.2em;
    line-height: 1.6;
    color: #333;
    transition: color var(--transition-duration);
  }
  
  .intro-content p {
    margin-bottom: 15px;
  }
  
  .intro-content a {
    color: var(--primary-color);
    text-decoration: none;
    transition: color var(--transition-duration);
  }
  
  .intro-content a:hover {
    color: darken(var(--primary-color), 10%);
    text-decoration: underline;
  }
  
  /* Add some animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .intro-card {
    animation: fadeIn 0.8s ease-out;
  }
  