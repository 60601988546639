/* sentimentDataDisplay.css */

.data-display {
    font-family: 'Arial', sans-serif;
    color: #333;
    padding: 20px;
    max-width: 700px;
    margin: 0 auto;
    background-color: #f0f4f8;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
    color: #222;
    margin-bottom: 8px;
  }
  
  .sentiment-container {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .sentiment-label {
    font-size: 18px;
    color: #444;
  }
  
  .sentiment-slider {
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    height: 12px;
    border-radius: 6px;
    background: linear-gradient(90deg, red 0%, yellow 50%, green 100%);
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
  }
  
  .sentiment-slider:hover {
    opacity: 1;
  }
  
  .summary {
    font-size: 16px;
    color: #444;
  }
  
  .why-grade {
    font-size: 16px;
    font-style: italic;
    color: #555;
  }
  
  .word-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .word-list-ul {
    list-style-type: none;
    padding: 0;
    font-size: 14px;
    color: #333;
  }
  
  .word-list-ul li {
    background-color: #eaf4fc;
    margin: 5px 0;
    padding: 5px 10px;
    border-radius: 4px;
  }
  
  .label {
    font-weight: bold;
    color: #222;
  }
  
  .category-labels {
    font-size: 16px;
    color: #666;
  }
  
  .article-link {
    font-size: 16px;
    color: #1e90ff;
    text-decoration: none;
    margin-top: 12px;
    display: inline-block;
  }
  
  .article-link:hover {
    text-decoration: underline;
  }
  
  .loading-text {
    font-size: 16px;
    color: #888;
    text-align: center;
  }
  #indicator {
    text-decoration: none;
    display: inline-block;
}

#indicator button {
    background-color: #4CAF50; /* Green background */
    border: none;
    color: white; /* White text */
    padding: 15px 30px; /* Padding */
    text-align: center; 
    text-decoration: none; 
    display: inline-block; 
    font-size: 16px; 
    margin: 10px 2px; 
    cursor: pointer; 
    border-radius: 12px; /* Rounded corners */
    transition: background-color 0.3s, transform 0.3s; /* Smooth transitions */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Subtle shadow */
}

#indicator button:hover {
    background-color: #45a049; /* Darker green on hover */
    transform: scale(1.05); /* Slightly increase size on hover */
}

#indicator button:active {
    background-color: #3e8e41; /* Even darker green when clicked */
    transform: scale(0.95); /* Slightly reduce size when clicked */
}
