.App {
  /* min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; */
}

.adjustWallet{
  height: 30px !important;
  align-self: center !important;
  text-align: center !important;
  align-items: center !important;
}
.whiteH{
  color: aliceblue;
}
body{
  background-color: #333;
}
.money{

  color: #9efa34;
}
.ul_coins{
  font-size: 17px;
}

.info-text {
  background-color: #f2f4f8; /* Light grey background for slight emphasis */
  color: #333; /* Dark grey text for high contrast and readability */
  font-family: 'Arial', sans-serif; /* Sets a readable, web-safe font */
  padding: 20px; /* Adds space inside the div */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Subtle shadow for a lifted effect */
  margin: 20px 0; /* Adds vertical spacing around the div */
  line-height: 1.6; /* Increases line height for better readability */
  font-size: 16px; /* Sets an appropriate font size for information text */
}

.queenCoin{
  background-color: rgb(255, 130, 108);
}



.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns on desktop */
  gap: 20px; /* Space between items */
  padding: 20px; /* Padding around the grid */
}

/* Media query for tablet screens - max-width can vary based on your design requirements */
@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr); /* Two columns on tablet */
  }
}

/* Media query for mobile screens */
@media (max-width: 580px) {
  .grid-container {
    grid-template-columns: 1fr; /* One column on mobile */
  }
}


.rowContainer {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.rowImage {
  width: 90px;
  height: 90px;
  margin-right: 20px;
}

.rowContent {
  display: flex;
  flex-direction: column;
}

.rowTitle {
  margin: 0 0 5px 0;
}

.rowLink {
  color: #0077cc;
  text-decoration: none;
}

.get_free_cnfts {
  display: inline-block;
  padding: 14px 28px;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  background: linear-gradient(45deg, #ff6ec4, #7873f5);
  border: none;
  border-radius: 5px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  outline: none;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.get_free_cnfts::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: rgba(255, 255, 255, 0.15);
  transition: all 0.75s ease;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
}

.get_free_cnfts:hover::before {
  transform: translate(-50%, -50%) scale(1);
}

.get_free_cnfts:hover {
  background: linear-gradient(45deg, #7873f5, #ff6ec4);
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2);
  transform: translateY(-3px);
}

.get_free_cnfts:active {
  background: linear-gradient(45deg, #ff6ec4, #7873f5);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  transform: translateY(2px);
}

.get_free_cnfts span {
  position: relative;
  z-index: 2;
}

.get_free_cnfts::after {
  content: '';
  position: absolute;
  left: -50%;
  top: -50%;
  width: 200%;
  height: 200%;
  background: rgba(255, 255, 255, 0.2);
  opacity: 0;
  transition: all 0.5s ease;
  transform: rotate(45deg);
}

.get_free_cnfts:hover::after {
  left: 100%;
  top: 100%;
  opacity: 0;
  transition: all 0.5s ease;
}

.get_free_cnfts span::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  mix-blend-mode: overlay;
  animation: shimmer 2s infinite;
  z-index: 1;
}

@keyframes shimmer {
  0% {
      transform: translateX(-100%);
  }
  50% {
      transform: translateX(100%);
  }
  100% {
      transform: translateX(-100%);
  }
}

#mintBtn {
  background-color: #6c63ff; /* Soft purple color */
  color: white;
  font-size: 1.2em;
  font-weight: bold;
  padding: 12px 24px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-family: 'Roboto', sans-serif; /* Modern font */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

#mintBtn:hover {
  background-color: #5a54e0; /* Darker purple on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

#mintBtn:active {
  background-color: #4c47c2; /* Even darker purple on click */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: scale(0.98); /* Slight scaling effect on click */
}

#mintBtn:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(108, 99, 255, 0.5); /* Focus ring */
}

#mintBtn img {
  margin-right: 8px; /* Space between icon and text */
  width: 24px;
  height: 24px;
}

#claimBtn {
  background-color: orange; /* Soft purple color */
  color: white;
  font-size: 1.2em;
  font-weight: bold;
  padding: 12px 24px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-family: 'Roboto', sans-serif; /* Modern font */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

#claimBtn:hover {
  transform: scale(1.05);
}

#claimBtn:disabled {
  background: #ccc;
  cursor: not-allowed;
  box-shadow: none;
  color: #666;
  transform: none;
}

#mint_info {
  display: inline-block;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 18px;
  color: #fdeded;
 
  letter-spacing: 0.5px;
  font-weight: 400;
  line-height: 1.5;

  background-color: rgba(212, 149, 32, 0.637); ;
  padding: 12px 20px;
  border-radius: 1px;
  box-shadow: 0 2px 1px rgba(241, 241, 241, 0.938);

}

.quanity_mint {
  font-weight: 600;
  color: #3a86ff;
  background-color: #3f3f3f;
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 20px;
}

#mint_info::before {
  content: "🔷";
  font-size: 18px;
  margin-right: 5px;
  vertical-align: middle;
}

#mint_info::after {
  content: "✨";
  font-size: 18px;
  margin-left: 5px;
  vertical-align: middle;
}

.solana_tokens2 {
  display: flex;
  align-items: center;
  font-family: 'Helvetica Neue', sans-serif;
  background-color: rgba(241, 241, 241, 0); ;
  padding: 12px 20px;
  border-radius: 1px;
  box-shadow: 0 2px 1px rgba(241, 241, 241, 0.938);
}

#token_image2 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
  object-fit: cover;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

#my_tokens2 {
  color: #aca8a8 !important;

  font-size: 18px;
  font-weight: 400;
}

.number_of_tokens2 {
  font-size: 22px;
  font-weight: 600;
  margin-left: 6px;
  color: #3a86ff;
}

.solana_tokens2 img:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease;
}
